import { FC } from 'react';
import React from 'react';

import RichText from '@components/RichText/RichText';
import { mapImage, wrap } from '@sitecore/common';
import { StepExplainerVerticalRendering } from '@sitecore/types/manual/StepExplainerVertical';
import { Heading, Stack, StepList, ButtonLink, TextLink } from '@sparky';

const StepExplainerVertical: FC<StepExplainerVerticalRendering> = ({ fields, params: { variant = 'interactive' } }) => {
  const { title, items: steps = [] } = fields ?? {};
  return (
    <Stack gap="10">
      <Heading as="h2" size="M">
        {title?.value}
      </Heading>
      <StepList variant={variant}>
        {steps.map(step => {
          const imageProps = mapImage(step.fields.image);
          return (
            <StepList.Item
              key={step.id}
              description={
                <Stack gap="6">
                  <Stack.Item>{wrap(step.fields.content, <RichText html={step.fields.content?.value} />)}</Stack.Item>
                  {step.fields?.buttonLink?.value?.href
                    ? wrap(
                        step.fields.buttonLink,
                        <ButtonLink href={step.fields.buttonLink.value.href}>
                          {step.fields.buttonLink.value.text}
                        </ButtonLink>,
                      )
                    : null}
                  {step.fields?.link?.value?.href
                    ? wrap(
                        step.fields.link,
                        <TextLink href={step.fields.link.value.href}>{step.fields.link.value.text}</TextLink>,
                      )
                    : null}
                </Stack>
              }
              image={{
                alt: imageProps.alt,
                src: imageProps.src ?? '',
                sources: imageProps.sources,
              }}>
              {step.fields?.title?.value}
            </StepList.Item>
          );
        })}
      </StepList>
    </Stack>
  );
};

export default StepExplainerVertical;
